

















import { Component, Vue } from 'vue-property-decorator'

import StudyReviewTypeForm from '@/partials/forms/Events/StudyReviewTypeForm.vue'
import EventStudyCriteriaTable from '@/partials/tables/Events/StudyCriteria/index.vue'

@Component({
  components: {
    EventStudyCriteriaTable,
    StudyReviewTypeForm
  }
})
export default class StudyReviewTypeEdit extends Vue {

}
