







































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import isEmpty from 'lodash/isEmpty'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    ArrowDown16,
    ArrowUp16,
    EventUserDropdown,
    PasswordConfirmField,
    GenericModalDelete,
    GenericModalForm,
    TrashCan16,
    Ticket16,
    Edit16
  }
})
export default class EventStudyReviewersTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) reviewTypeId!: string
  studyCriteria = []
  deleteModalIsOpened = false
  selectedStudyCriteria = {
    id: '',
    title: ''
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria`)
      .then(response => {
        this.studyCriteria = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.studyCriteria[targetIndex]

    this.studyCriteria.splice(targetIndex, 1)
    this.studyCriteria.splice(index, 0, swappingChoice)

    this.submitOrder()
  }

  submitOrder () {
    const orderedCriteria = this.studyCriteria.map((criterion: any, index: number) => {
      return { id: criterion.id, order: index }
    })

    const submitForm = {
      criteria: orderedCriteria
    }

    axios.put(`event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria/reorder`, submitForm)
      .then((response) => {
        this.studyCriteria = camelCaseKeys(response.data.data).map((criterion: any, index: number) => {
          criterion.order = index
          return criterion
        })
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Critério foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedStudyCriteria) ? '' : this.selectedStudyCriteria.id] }
  }

  get deleteUrl () {
    return isEmpty(this.selectedStudyCriteria) ? '' : `/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria/${this.selectedStudyCriteria.id}`
  }
}
